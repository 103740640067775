import { useState, useCallback, useEffect } from 'react';

import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { memoize } from 'lodash';

import { getCurrentUserSession } from 'remote/auth';
import {
  completeNewPasswordChallenge as cognitoCompleteNewPasswordChallenge,
  verifyTokenForAssociation as cognitoVerifyTokenForAssociation,
  verifyTokenForSignIn as cognitoVerifyTokenForSignIn,
  signIn as cognitoSignIn,
  signOut as cognitoSignOut,
  getResetPasswordCode as cognitoGetResetPasswordCode,
  resetPassword as cognitoResetPassword,
} from 'remote/auth/cognito';
import { AuthFlags, isCognitoUserSession } from 'state/auth/types';

import {
  getUserPermissions,
  getUserLimits,
  Role,
} from '../../../services/graphql/src/lib/users/roles-and-permissions';
import { BRAND, ENVIRONMENT } from '../../constants';

interface IAuthEvent {
  payload: {
    event: string;
    data?: any;
    message?: string;
  };
}

const decodeJWTPayload = (jwt: string) => {
  const payload = jwt.split('.')[1];

  try {
    return JSON.parse(atob(payload));
  } catch (err) {
    return {};
  }
};

const getIdFromJWT = memoize((jwt) => {
  const payload = decodeJWTPayload(jwt) || {};

  const cognitoId: string = payload.sub ?? '';
  const email: string = payload.email ?? '';

  let roles: string[] = payload['cognito:groups'] ?? [];

  const isOktaAuth = !!payload.identities?.some(
    ({ providerName }: { providerName: string }) => providerName === 'Okta',
  );

  if (isOktaAuth) {
    const oktaRegions: string[] = JSON.parse(payload['custom:rbiCognitoDataSet'] ?? '[]');
    const oktaAccessLevel: string[] = JSON.parse(payload['custom:rbiCognitoAccess'] ?? '[]');

    const countryIndex = 0;
    const brandIndex = 1;
    const stageIndex = 2;

    const regionRoles = [
      ...new Set(
        oktaRegions
          .filter(
            (role) => role[brandIndex] === BRAND.toUpperCase() && role[stageIndex] === ENVIRONMENT,
          )
          .map((role) => role[countryIndex].toLowerCase() + '-admin'),
      ),
    ];

    const accessLevels = [
      ...new Set(oktaAccessLevel.map((accessLevel) => accessLevel.toLowerCase())),
    ];

    roles = [...accessLevels, ...regionRoles].filter(Boolean);
  }

  return { cognitoId, email, roles, isOktaAuth };
});

const useAuth = () => {
  const [userSession, setSession] = useState<CognitoUserSession | AuthFlags | null>(null);
  const [needsReset, setNeedsReset] = useState(true);

  const getJWT = useCallback((session: CognitoUserSession | AuthFlags | null) => {
    if (!session) {
      return '';
    }
    if (isCognitoUserSession(session)) {
      return session.getIdToken().getJwtToken();
    }
    return '';
  }, []);

  const getIdentityFromJWT = useCallback(getIdFromJWT, [getIdFromJWT]);

  const signOut = useCallback(async () => {
    const signOutStatus = await cognitoSignOut();
    if (signOutStatus) {
      if (!needsReset) {
        setNeedsReset(true);
      }
    }
  }, [needsReset, setNeedsReset]);

  const getCurrentSession = useCallback(() => getCurrentUserSession(signOut), [signOut]);

  const resetSession = useCallback(async () => {
    const session = await getCurrentSession();
    setSession(session);
  }, [getCurrentSession]);

  const isAuthenticated = useCallback(() => {
    if (userSession) {
      if (
        'totpRequired' in userSession ||
        'shouldChangePassword' in userSession ||
        'totpSecret' in userSession
      ) {
        return false;
      }
      if (userSession.isValid()) {
        return true;
      }
    }
    return false;
  }, [userSession]);

  const changePassword = useCallback(({ newPassword }) => {
    return cognitoCompleteNewPasswordChallenge({ newPassword }).then((data) => data);
  }, []);

  const signIn = useCallback(async ({ email, password }: { email: string; password: string }) => {
    const session = await cognitoSignIn({ username: email.toLowerCase(), password });
    setSession(session);
    return session;
  }, []);

  const verifyTOTPForAssociation = useCallback(
    ({ token }) =>
      cognitoVerifyTokenForAssociation(token).then((session) => {
        setSession(session);
        return session;
      }),
    [],
  );

  const verifyTOTPForSignIn = useCallback(({ token }) => {
    return cognitoVerifyTokenForSignIn(token).then((session) => {
      setSession(session);
      return session;
    });
  }, []);

  const getCtx = useCallback(() => {
    const jwt = getJWT(userSession);
    const { email, cognitoId, roles } = getIdentityFromJWT(jwt);
    const permissions = getUserPermissions(roles as Role[]);
    const limits = getUserLimits(roles as Role[]);
    return {
      // Computed values
      email,
      cognitoId,
      needsReset,
      userSession,
      roles,
      permissions,
      limits,

      // Functions
      changePassword,
      getCurrentSession,
      isAuthenticated,
      signIn,
      signOut,
      resetPassword: cognitoResetPassword,
      getResetPasswordCode: cognitoGetResetPasswordCode,
      verifyTOTPForAssociation,
      verifyTOTPForSignIn,
    };
  }, [
    changePassword,
    getCurrentSession,
    getIdentityFromJWT,
    getJWT,
    isAuthenticated,
    needsReset,
    signIn,
    signOut,
    userSession,
    verifyTOTPForAssociation,
    verifyTOTPForSignIn,
  ]);

  const authEventHandler = useCallback(({ payload }: IAuthEvent) => {
    switch (payload.event) {
      case 'cognitoHostedUI':
        Auth.currentAuthenticatedUser()
          .then((authenticatedUser: CognitoUser) => {
            setSession(authenticatedUser.getSignInUserSession());
          })
          .catch((error) => {
            throw new Error(error);
          });
        break;
      case 'cognitoHostedUI_failure':
      case 'signIn_failure':
        setSession(null);
        throw new Error('Failed to sign in user via Okta');
      case 'signOut':
        setSession(null);
        break;
    }
  }, []);

  useEffect(() => {
    if (needsReset) {
      resetSession()
        .then(() => setNeedsReset(false))
        .catch((error) => console.error(error));
    }

    return Hub.listen('auth', authEventHandler);
  }, [needsReset, resetSession, authEventHandler, userSession]);

  return {
    ...getCtx(),
  };
};

export default useAuth;
