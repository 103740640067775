import config from 'config/config.json';
import { AwsRegion, Brand } from 'types';

// TODO: add some validation that requires BRAND & ENVIRONMENT
export const BRAND: Brand = (process.env.REACT_APP_RBI_BRAND as Brand) || Brand.PLK;

export const REGION: string = AwsRegion.US;

// TODO: remove the split in the future.
// We previously specified environment as 'dev-th' instead of 'dev'
export const ENVIRONMENT: string = `${process.env.REACT_APP_RBI_ENV}`.split('-')[0];

const getEnvKeyOrDefault = (key: string): string => {
  return process.env[`REACT_APP_${key}`] || config[REGION][ENVIRONMENT][key] || '';
};

export const USER_POOL_CLIENT_ID: string = getEnvKeyOrDefault('USER_POOL_CLIENT_ID');

export const USER_POOL_ID: string = getEnvKeyOrDefault('USER_POOL_ID');

export const OKTA_USER_POOL_ID: string = getEnvKeyOrDefault('OKTA_USER_POOL_ID');

export const OKTA_USER_POOL_CLIENT_ID: string = getEnvKeyOrDefault('OKTA_USER_POOL_CLIENT_ID');

export const MPARTICLE_API_KEY: string = getEnvKeyOrDefault('RBI_MPARTICLE_KEY');

export const DOMAIN: string = `${ENVIRONMENT}-${BRAND}-admin`;

export const IS_COGNITO_LOGIN_DISABLED = false;

export const GQL_URL: string =
  process.env.REACT_APP_GQL_URL || `https://${DOMAIN}.rbictg.com/graphql`;

export const isProduction: boolean = ENVIRONMENT === 'prod';

export const isLocalhost: boolean = !!window?.location?.hostname?.includes('localhost');

export const USER_POOL_ENDPOINT: string = `https://cognito-idp.${REGION}.amazonaws.com/`;

export const OKTA_OAUTH_DOMAIN: string = `okta-support-tool-${BRAND}-${ENVIRONMENT}.auth.${REGION}.amazoncognito.com`;

export const OKTA_AUTH_OPTIONS = {
  // https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#scoped-configuration
  Auth: {
    region: REGION,
    userPoolId: OKTA_USER_POOL_ID,
    userPoolWebClientId: OKTA_USER_POOL_CLIENT_ID,
    mandatorySignIn: false,
    oauth: {
      domain: OKTA_OAUTH_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: 'code',
    },
  },
};
