export const routes = {
  customer: '/customer',
  default: '/',
  giftCard: '/giftcard',
  loyaltyLegacy: '/loyalty-legacy',
  ctgLoyaltyTransaction: '/ctg-loyalty-transaction',
  ctgLoyalty: '/ctg-loyalty',
  offers: '/offers',
  orders: '/orders',
  releaseNotes: '/release-notes',
  history: '/history',
  signIn: '/signin',
  store: '/store',
  supportHistory: '/support-history',
};

export const nestedResources = {
  [routes.customer]: [
    routes.giftCard,
    routes.ctgLoyalty,
    routes.offers,
    routes.orders,
    routes.supportHistory,
  ].map((resource) => resource.replace('/', '')),
};

export const isRouteSelected = (route: string, pathName: string) => {
  // This function is not an exact science
  // One day we will migrate to React Router and use https://v5.reactrouter.com/web/api/matchPath
  // Until the this appears to be the best we can do...
  const routeResource = route.replace('/', '');
  const pathParts = pathName.split('/').filter(Boolean);
  const firstPathPart = `/${pathParts[0]}`;
  const lastPathPart = pathParts.slice(-1)[0];
  const isCurrentPathNestedResource = !!nestedResources[firstPathPart]?.includes(lastPathPart);
  if (isCurrentPathNestedResource) {
    // we want the offers tab to remain selected whether the agent is viewing offers v1 or v2
    if (lastPathPart.startsWith('offers')) {
      return routeResource.includes('offers');
    }
    // all other nested resources
    return lastPathPart === routeResource;
  }
  if (pathParts.length === 4) {
    // special case for double nested orders route: customers/:customerId/orders/:orderId
    return pathParts[2] === routeResource;
  }
  if (route === routes.giftCard) {
    return firstPathPart === route || lastPathPart === routeResource;
  }
  if (pathParts.length === 1 || pathParts.length === 2) {
    return firstPathPart === route;
  }
};

export const expandPath = (resource: string, params: Record<string, string>) => {
  const { customerId = '', storeId = '', orderId = '' } = params;
  if (resource === routes.customer && !!customerId) {
    return `${routes.customer}/${customerId}`;
  }
  if (resource === routes.ctgLoyalty && !!customerId) {
    return `${routes.customer}/${customerId}${routes.ctgLoyalty}`;
  }
  if (resource === routes.orders && !!customerId && !orderId) {
    return `${routes.customer}/${customerId}${routes.orders}`;
  }
  if (resource === routes.orders && !!customerId && !!orderId) {
    return `${routes.customer}/${customerId}${routes.orders}/${orderId}`;
  }
  if (resource === routes.offers && !!customerId) {
    return `${routes.customer}/${customerId}${routes.offers}`;
  }
  if (resource === routes.giftCard && !!customerId) {
    return `${routes.customer}/${customerId}${routes.giftCard}`;
  }
  if (resource === routes.supportHistory && !!customerId) {
    return `${routes.customer}/${customerId}${routes.supportHistory}`;
  }
  if (resource === routes.store && !!storeId) {
    return `${routes.store}/${storeId}`;
  }
  return resource;
};
