import { IBrandConfigs } from './brand-config';
import plkConfigs from './plk-configs';

/*
 * The idea behind this file is to keep all brand driven logic
 * together in one place so that we're not constantly pulling in
 * Brand and BRAND from utils/constants in order to conditionally
 * render different areas of the app
 */

const brandConfigs: IBrandConfigs = plkConfigs;

export default brandConfigs;
